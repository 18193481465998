import { SnackbarProvider } from 'notistack'
import { ErrorBoundary } from 'react-error-boundary'
import { RouteObject, useRoutes } from 'react-router-dom'
import ErrorFallback from 'ui/common/components/ErrorFallback'
import {
	PreferenceParam,
	PreferencesContext,
	useProviderPreference,
} from 'ui/common/hooks/theme'
import NotFound from 'ui/pages/NotFound'

import { Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import Root from '../Root'
import { globalStyle } from './emotion'

const snackProps = {
	domRoot: document.getElementById('snack')!,
}

export type ApplicationProps = {
	rootRoutes: RouteObject[]
} & PreferenceParam

const Application = (p: ApplicationProps) => {
	const { theme, ...providerPreference } = useProviderPreference({
		theme: p.theme,
	})
	const routes = useRoutes(p.rootRoutes.concat(NotFound))

	return (
		<PreferencesContext.Provider value={providerPreference}>
			<ThemeProvider theme={theme}>
				<Global styles={globalStyle} />
				<CssBaseline />
				<SnackbarProvider
					maxSnack={3}
					disableWindowBlurListener
					anchorOrigin={
						screen.width < theme.breakpoints.values['sm'] || 'Cypress' in window
							? { vertical: 'bottom', horizontal: 'left' }
							: { vertical: 'top', horizontal: 'right' }
					}
					{...snackProps}
				>
					<Root />
					<meta name="theme-color" content={theme.palette.background.default} />
					{/*
					@ts-ignore NOTE: remove me if react type 18 stable */}
					<ErrorBoundary FallbackComponent={ErrorFallback}>
						{routes}
					</ErrorBoundary>
				</SnackbarProvider>
			</ThemeProvider>
		</PreferencesContext.Provider>
	)
}

export default Application
