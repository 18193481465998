import { ApplicationProps } from 'ui/common/components/Application'
import seedSandBold from 'ui/fonts/LINESeedSansTH_W_Bd.woff'
import seedSandRegular from 'ui/fonts/LINESeedSansTH_W_Rg.woff'

import { PaletteColorOptions } from '@mui/material/styles/createPalette'

import Location from './Location'

const MAIN_FONT = 'SeedSand'

export const appProps: ApplicationProps = {
	rootRoutes: [Location],
	theme: () => ({
		palette: {
			primary: '#007565' as PaletteColorOptions,
		},
		typography: {
			fontFamily: [MAIN_FONT, 'Helvetica', 'Arial', 'sans-serif'].join(),
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: [
					[seedSandRegular, 'Regular', 400],
					[seedSandBold, 'Bold', 500],
				]
					.map(
						([font, type, fontWeight]) => /* css */ `
					@font-face {
					font-family: '${MAIN_FONT}';
					font-style: normal;
					font-display: swap;
					font-weight: ${fontWeight};
					src: local('${MAIN_FONT}-${type}'), url(${font}) format('woff2');
					}
				`,
					)
					.join('\n'),
			},
		},
	}),
}
